import React, { useCallback, useEffect, useState, createRef, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import html2canvas from 'html2canvas';
import { customAlphabet } from 'nanoid';
import { fetchAuthSession } from 'aws-amplify/auth';

import Typography from '../Typography';
import Input from '../Input';
import Tooltip from '../Tooltip';
import IconButton from '../IconButton';
import Icon from '../Icon';
import Select from '../Select';
import InputLabelWrapper from '../InputLabelWrapper';
import Button, { ButtonEnums } from '../Button';

axios.defaults.baseURL = process.env.API_BASE_URL;

const MEDIA_URL = process.env.MEDIA_URL;

const alphabet = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

function dataURItoBlob(dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0) {
    byteString = atob(dataURI.split(',')[1]);
  } else {
    byteString = unescape(dataURI.split(',')[1]);
  }

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
}

const FILE_TYPE_OPTIONS = [
  {
    value: 'jpg',
    label: 'jpg',
  },
  {
    value: 'png',
    label: 'png',
  },
];

const imageTypes = [
  'Flexion',
  'Flexion - 1 Year',
  'Flexion - 2 Years',
  'Extension',
  'Extension - 1 Year',
  'Extension - 2 Years',
  'Sagittal MR',
  'Sagittal MR - 1 Year',
  'Sagittal MR - 2 Years',
  'Key Axial 1',
  'Key Axial 1 - 1 Year',
  'Key Axial 1 - 2 Years',
  'Key Axial 2',
  'Key Axial 2 - 1 Year',
  'Key Axial 2 - 2 Years',
  'Key Axial 3',
  'Key Axial 3 - 1 Year',
  'Key Axial 3 - 2 Years',
  'Key Axial 4',
  'Key Axial 4 - 1 Year',
  'Key Axial 4 - 2 Years',
];

const DEFAULT_FILENAME = 'image';

const REFRESH_VIEWPORT_TIMEOUT = 100;

const ViewportUploadForm = ({
  activeViewportElement,
  onClose,
  updateViewportPreview,
  enableViewport,
  disableViewport,
  toggleAnnotations,
  loadImage,
  downloadBlob,
  defaultSize,
  minimumSize,
  maximumSize,
  canvasClass,
  uiNotificationService,
}) => {
  const { t } = useTranslation('Modals');

  const [filename, setFilename] = useState(DEFAULT_FILENAME);
  const [fileType, setFileType] = useState(['jpg']);
  const [isLoading, setIsLoading] = useState(false);
  const [imageType, setImageType] = useState({ value: 'Flexion', label: 'Flexion' });
  const [caption, setCaption] = useState('');

  const [dimensions, setDimensions] = useState({
    width: defaultSize,
    height: defaultSize,
  });

  const [showAnnotations, setShowAnnotations] = useState(true);

  const [keepAspect, setKeepAspect] = useState(true);
  const [aspectMultiplier, setAspectMultiplier] = useState({
    width: 1,
    height: 1,
  });

  const [viewportElement, setViewportElement] = useState();
  const [viewportElementDimensions, setViewportElementDimensions] = useState({
    width: defaultSize,
    height: defaultSize,
  });

  const [downloadCanvas, setDownloadCanvas] = useState({
    ref: createRef(),
    width: defaultSize,
    height: defaultSize,
  });

  const [viewportPreview, setViewportPreview] = useState({
    src: null,
    width: defaultSize,
    height: defaultSize,
  });

  const [error, setError] = useState({
    width: false,
    height: false,
    filename: false,
  });

  const hasError = Object.values(error).includes(true);

  const refreshViewport = useRef(null);

  const onKeepAspectToggle = () => {
    const { width, height } = dimensions;
    if (!keepAspect) {
      const aspectMultiplier = {
        width: width / height,
        height: height / width,
      };
      setAspectMultiplier(aspectMultiplier);
    }

    setKeepAspect(!keepAspect);
  };

  const uploadImage = async () => {
    setIsLoading(true);

    const divForDownloadViewport = document.querySelector(
      `div[data-viewport-uid="cornerstone-viewport-download-form"]`
    );

    const canvas = await html2canvas(divForDownloadViewport);

    const base64Image = canvas.toDataURL(fileType, 1.0);
    // const base64 = getBase64StringFromDataURL(base64Image)

    const type = base64Image.split(';')[0].split(':')[1];

    const caseId = localStorage.getItem('studyCaseId');
    const parentCaseId = localStorage.getItem('parentCaseId');

    if (!parentCaseId) {
      window.parent.postMessage(
        JSON.stringify({ image: base64Image, caption, label: imageType.value, type }),
        '*'
      );

      uiNotificationService.show({
        title: 'The image has been uploaded',
        type: 'success',
        duration: 5000,
      });

      setIsLoading(false);
      onClose();

      return;
    }

    const cookies = await window.cookieStore.getAll();
    const token = cookies.find(cookie => cookie.name.includes('idToken')).value;

    const { data: caseData } = await axios.get(`case/${caseId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const { data } = await axios.post(
      `case/${caseId}/upload`,
      [
        {
          name: (filename || DEFAULT_FILENAME) + `.${type.split('/')[1]}`,
          type: type,
        },
      ],
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const blob = dataURItoBlob(base64Image);
    const file = new FormData();
    file.append('file', file);

    const res = await axios.put(data[0].url, blob, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    const nanoid = customAlphabet(alphabet, 14);
    const field = nanoid();

    const { data: { visit } } = await axios.get(
      `case/${caseId}/visit/${window.localStorage.getItem('visit')}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        baseURL: process.env.API_BASE_URL,
      }
    );

    await axios.put(
      `case/${caseId}/visit/${visit.visitId}`,
      {
        assets: [
          ...visit.assets,
          {
            field: `media_${field}`,
            path: `${MEDIA_URL}/${data[0].path}`,
            type: data[0].type,
            label: imageType.value,
            caption: caption,
            order: visit.assets.length + 1,
          },
        ].map(a => ({ ...a, path: a.path.match(/^(https?:\/\/[^/]+)?\/?(.*?)(?:\?|#|$)/)[2] })),
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    uiNotificationService.show({
      title: 'The image has been uploaded',
      type: 'success',
      duration: 5000,
    });

    setIsLoading(false);
    onClose();
  };

  /**
   * @param {object} value - Input value
   * @param {string} dimension - "height" | "width"
   */
  const onDimensionsChange = (value, dimension) => {
    const oppositeDimension = dimension === 'height' ? 'width' : 'height';
    const sanitizedTargetValue = value.replace(/\D/, '');
    const isEmpty = sanitizedTargetValue === '';
    const newDimensions = { ...dimensions };
    const updatedDimension = isEmpty ? '' : Math.min(sanitizedTargetValue, maximumSize);

    if (updatedDimension === dimensions[dimension]) {
      return;
    }

    newDimensions[dimension] = updatedDimension;

    if (keepAspect && newDimensions[oppositeDimension] !== '') {
      newDimensions[oppositeDimension] = Math.round(
        newDimensions[dimension] * aspectMultiplier[oppositeDimension]
      );
    }

    // In current code, keepAspect is always `true`
    // And we always start w/ a square width/height
    setDimensions(newDimensions);

    // Only update if value is non-empty
    if (!isEmpty) {
      setViewportElementDimensions(newDimensions);
      setDownloadCanvas(state => ({
        ...state,
        ...newDimensions,
      }));
    }
  };

  const error_messages = {
    width: 'The minimum valid width is 100px.',
    height: 'The minimum valid height is 100px.',
    filename: 'The file name cannot be empty.',
  };

  const renderErrorHandler = errorType => {
    if (!error[errorType]) {
      return null;
    }

    return (
      <Typography
        className="mt-2 pl-1"
        color="error"
      >
        {error_messages[errorType]}
      </Typography>
    );
  };

  const validSize = useCallback(
    value => (value >= minimumSize ? value : minimumSize),
    [minimumSize]
  );

  const loadAndUpdateViewports = useCallback(async () => {
    const { width: scaledWidth, height: scaledHeight } = await loadImage(
      activeViewportElement,
      viewportElement,
      dimensions.width,
      dimensions.height
    );

    toggleAnnotations(showAnnotations, viewportElement, activeViewportElement);

    const scaledDimensions = {
      height: validSize(scaledHeight),
      width: validSize(scaledWidth),
    };

    setViewportElementDimensions(scaledDimensions);
    setDownloadCanvas(state => ({
      ...state,
      ...scaledDimensions,
    }));

    const {
      dataUrl,
      width: viewportElementWidth,
      height: viewportElementHeight,
    } = await updateViewportPreview(viewportElement, downloadCanvas.ref.current, fileType);

    setViewportPreview(state => ({
      ...state,
      src: dataUrl,
      width: validSize(viewportElementWidth),
      height: validSize(viewportElementHeight),
    }));
  }, [
    loadImage,
    activeViewportElement,
    viewportElement,
    dimensions.width,
    dimensions.height,
    toggleAnnotations,
    showAnnotations,
    validSize,
    updateViewportPreview,
    downloadCanvas.ref,
    fileType,
    imageType,
  ]);

  useEffect(() => {
    enableViewport(viewportElement);

    return () => {
      disableViewport(viewportElement);
    };
  }, [disableViewport, enableViewport, viewportElement]);

  useEffect(() => {
    if (refreshViewport.current !== null) {
      clearTimeout(refreshViewport.current);
    }

    refreshViewport.current = setTimeout(() => {
      refreshViewport.current = null;
      loadAndUpdateViewports();
    }, REFRESH_VIEWPORT_TIMEOUT);
  }, [
    activeViewportElement,
    viewportElement,
    showAnnotations,
    dimensions,
    loadImage,
    toggleAnnotations,
    updateViewportPreview,
    fileType,
    imageType,
    downloadCanvas.ref,
    minimumSize,
    maximumSize,
    loadAndUpdateViewports,
  ]);

  useEffect(() => {
    const { width, height } = dimensions;
    const hasError = {
      width: width < minimumSize,
      height: height < minimumSize,
      filename: !filename,
    };

    setError({ ...hasError });
  }, [dimensions, filename, minimumSize]);

  return (
    <div>
      <div className="mt-6 flex flex-col">
        <div className="flex">
          <div className="w-1/4 pl-6">
            <div>
              <InputLabelWrapper
                sortDirection="none"
                label={'Image type'}
                isSortable={false}
                onLabelClick={() => {}}
              >
                <Select
                  className="mt-2 text-white"
                  isClearable={false}
                  value={imageType}
                  data-cy="image-type"
                  onChange={setImageType}
                  hideSelectedOptions={false}
                  options={imageTypes.map(i => ({ value: i, label: i }))}
                  placeholder="Image type"
                />
              </InputLabelWrapper>
            </div>
            <div className="mt-4 w-full">
              <Input
                type="text"
                label={'Caption'}
                value={caption}
                onChange={evt => setCaption(evt.target.value)}
                data-cy="image-caption"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="mt-8">
        <div
          className="bg-secondary-dark border-secondary-primary w-max-content min-w-full rounded p-4"
          data-cy="image-preview"
        >
          <Typography variant="h5">{t('Image preview')}</Typography>
          {activeViewportElement && (
            <div
              className="mx-auto my-2"
              style={{
                height: viewportElementDimensions.height,
                width: viewportElementDimensions.width,
              }}
              ref={ref => setViewportElement(ref)}
            ></div>
          )}
          {!activeViewportElement && (
            <Typography className="mt-4">{t('Active viewport has no displayed image')}</Typography>
          )}
        </div>
      </div>

      <div className="mt-4 flex justify-end">
        <Button
          name="cancel"
          type={ButtonEnums.type.secondary}
          onClick={onClose}
        >
          {t('Cancel')}
        </Button>
        <Button
          className="ml-2"
          disabled={hasError || isLoading}
          onClick={uploadImage}
          type={ButtonEnums.type.primary}
          name={'upload'}
        >
          {isLoading ? 'Uploading...' : 'Upload'}
        </Button>
      </div>
    </div>
  );
};

export default ViewportUploadForm;
