import JsCookie from 'js-cookie';

interface KeyValueStorageInterface {
	setItem(key: string, value: string): Promise<void>;
	getItem(key: string): Promise<string | null>;
	removeItem(key: string): Promise<void>;
	clear(): Promise<void>;
}

type SameSite = 'strict' | 'lax' | 'none';

type CookieStorageData = {
	domain?: string;
	path?: string;

	/**
	 * Expiration in days
	 */
	expires?: number;
	secure?: boolean;
	sameSite?: SameSite;
};

export class CustomStorage {
	path: string;
	domain?: string;
	expires?: number;
	sameSite?: SameSite;
	secure?: boolean;

	constructor(data: CookieStorageData = {}) {
		const { path, domain, expires, sameSite, secure } = data;
		this.domain = domain;
		this.path = path ? path : '/';
		this.expires = data.hasOwnProperty('expires') ? expires : 365;
		this.secure = data.hasOwnProperty('secure') ? secure : true;

		if (data.hasOwnProperty('sameSite')) {
			if (!sameSite || !['strict', 'lax', 'none'].includes(sameSite)) {
				throw new Error(
					'The sameSite value of cookieStorage must be "lax", "strict" or "none".'
				);
			}
			if (sameSite === 'none' && !this.secure) {
				throw new Error(
					'sameSite = None requires the Secure attribute in latest browser versions.'
				);
			}
			this.sameSite = sameSite;
		}
	}

	async setItem(key: string, value: string) {
    if (key.includes('idToken') || key.includes('accessToken')) {
      JsCookie.set(key, value, this.getData());
    } else {
      window.localStorage.setItem(key, value);
    }
	}

	async getItem(key: string) {
    if (key.includes('idToken') || key.includes('accessToken')) {
      const item = JsCookie.get(key);
      return item ?? null;
    } else {
      return window.localStorage.getItem(key);
    }
	}

	async removeItem(key: string) {
    if (key.includes('idToken') || key.includes('accessToken')) {
      JsCookie.remove(key, this.getData());
    } else {
      window.localStorage.removeItem(key);
    }
	}

	async clear() {
		const cookie = JsCookie.get();
		const promises = Object.keys(cookie).map(key => this.removeItem(key));
		await Promise.all(promises);

    window.localStorage.clear();
	}

	private getData(): CookieStorageData {
		return {
			path: this.path,
			expires: this.expires,
			domain: this.domain,
			secure: this.secure,
			...(this.sameSite && { sameSite: this.sameSite }),
		};
	}
}
